import React, { useRef, useState } from "react"
import { createBrowserRouter, Link, NavLink, Outlet, RouterProvider, useLocation} from "react-router-dom"
import { Button } from "@/components/ui/button"
import { useEffect } from 'react'
import { BookText, ChartNoAxesCombined, ExternalLink, Home, Link2, Link as CustomLink, Menu, PhoneCall, UserIcon, ArrowRight } from 'lucide-react'
import { cn } from "@/lib/utils"
import ErrorBoundary from "@/blog/ErrorBoundary"
import LoadingComponent from "@/components/Loading"
import {fetchDashboardData} from '@/admin/dashboard'
const AdminDashboard = React.lazy(() => import('@/admin/dashboard'));

export const adminRouter = [
    {
        path: 'dashboard',
        Component: AdminDashboard,
        loader: fetchDashboardData
    },
    {
        path: 'subscriptions',
        Component: React.lazy(() => import('@/admin/subscriptions')),
        loader: () => import('@/admin/subscriptions').then((module) => module.fetchSubscribed())
    },
    {
      path: 'users',
      Component: React.lazy(() => import('@/admin/users')),
      loader: (k: any) => import('@/admin/users').then((module) => module.fetchUsers(k))
    }
]


const navItems = [
  { id: "dashboard", label: "Dashboard", href: "/admin/dashboard", icon: Home },
  { id: "subscriptions", label: "Subscriptions", href: "/admin/subscriptions", icon: ArrowRight },
  { id: "users", label: "Users", href: "/admin/users", icon: ArrowRight },
  { id: "account", label: "Account", href:"/app/account", icon: UserIcon }
]


const NavItem = ({ label, icon: Icon, isSelected, href }: any) => {
  if(href.startsWith("http")) {
    return <a href={href} target="_blank" className={cn(
      "flex items-center relative p-2 px-4 rounded-lg cursor-pointer flex",
      isSelected ? "font-bold text-primary" : "hover:font-bold"
    )}>
      
      {Icon && <Icon className="w-5 h-5 mr-3" />}
      <span>{label}</span>
      <ExternalLink className="w-3 h-3 ml-3" />
    </a>
  }
  return (
    <Link to={href} className={cn(
      "flex items-center relative p-2 px-4 rounded-lg cursor-pointer flex",
      isSelected ? "font-bold text-primary" : "hover:font-bold"
    )}>
      
      {Icon && <Icon className="w-5 h-5 mr-3" />}
      <span>{label}</span>
      {isSelected && <span className="absolute w-1 h-full bg-primary right-0 "></span>}
    </Link>
  );
};


export default function Admin() {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768)
      if (window.innerWidth >= 768) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
    }

    checkIsMobile()
    window.addEventListener('resize', checkIsMobile)

    return () => {
      window.removeEventListener('resize', checkIsMobile)
    }
  }, [])

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="flex flex-col bg-background w-full">
      {/* Side Navigation */}
      <header className="shadow-lg fixed w-full top-0 left-0 p-4 bg-background z-20 flex items-center md:hidden border-b-[1px]">
        {!isOpen && (
          <Button
            className="mr-4"
            onClick={toggleSidebar}
            size="icon"
            variant="ghost"
          >
            <Menu className="h-4 w-4" />
            <span className="sr-only">Toggle Menu</span>
          </Button>
        )}
        <h1 className="text-xl font-bold">SMLER</h1>
      </header>
      <div className="flex flex-1">
      <aside
          className={`${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          } fixed md:relative z-40 bg-background h-screen w-64 top-0 shadow-lg transition-transform duration-300 ease-in-out md:translate-x-0`}
        >
        <nav className="w-64 py-4 border-r-[1px] h-screen">
          <div className="flex justify-between flex-col h-full">
            {/* <AccountCard name={user.name} phoneNumber={user.phone} /> */}
            <div className="space-y-2 relative">
              <NavLink to="/" className="flex flex-row items-center h-16 px-4">
                <img src="/favicon-32x32.png" alt="logo" className="h-10" />
                <span className="text-2xl font-bold pl-2">SMLER</span>
              </NavLink>
              {navItems.map((item) => (
                <div key={item.id}>
                  <NavItem
                      label={item.label}
                      icon={item.icon}
                      isSelected={location.pathname === item.href}
                      href={item.href}
                    />
                </div>
              ))}
            </div>
          </div>
        </nav>
      </aside>

      {/* Main Content */}
      <main className="flex-1 p-2 md:p-6 pt-20 md:pt-2 md:h-screen md:overflow-y-auto w-full">
      <ErrorBoundary>
        <React.Suspense fallback={<LoadingComponent />}>
            <Outlet />
        </React.Suspense>
      </ErrorBoundary>
      </main>
      {isMobile && isOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-5"
            onClick={toggleSidebar}
          ></div>
        )}
      </div>
    </div>
  )
}
