import { TypographyH3 } from "@/components/ui/typography/TypographyH1"
import { Link, MousePointerClick } from "lucide-react"
import { createBrowserRouter, RouterProvider, useFetcher, useLoaderData } from "react-router-dom"
import getDomain from "../getDomain"

const formatNumber = (num: number) => {
    if(!num) {
      return '0'
    }
    return num.toLocaleString('en-IN')
  }

const Dashboard = ({cards, heading}: {cards: {heading: string, value: string, icon: any}[], heading: string}) => {
  
    return (
      <div>
        <div className="py-4 pb-8">
          <TypographyH3 className="pb-6">{heading}</TypographyH3>
          <div className="grid grid-cols-1 gap-5 mx-auto sm:grid-cols-2 lg:grid-cols-3">
              {cards.map((card, index) => (
                <div className="border border-gray-700 rounded-xl" key={index}>
                  <div className="px-5 py-4">
                      <p className="text-md tracking-wider uppercase flex items-center"> {card.icon} {card.heading}</p>
                      <div className="flex items-center justify-between mt-3">
                          <p className="text-3xl font-bold">{card.value}</p>
                      </div>
                  </div>
                </div>
              ))}
              
          </div>
        </div>
      </div>
    );
}

export async function fetchDashboardData() {
    const response = await fetch(getDomain()+'/api/v1/admin/dashboard', {
        headers: {
            'authorization': 'Bearer ' + localStorage.getItem('url-shorter-token')
        }
    })
    if(!response.ok) {
        throw new Error("Failed to fetch data from server")
    }
    const data = await response.json()
    return data
}


function AdminDashboard(props: any) {

    const data = useLoaderData() as {
        users: number,
        subscriptions: number,
        urls: number
    }

    const cards = [
        {heading: 'Users', value: formatNumber(data.users), icon: <Link className='w-4 h-4 mr-2'/>},
        {heading: 'Subscribed', value: formatNumber(data.subscriptions), icon: <Link className='w-4 h-4 mr-2'/>},
        {heading: 'Total Links', value: formatNumber(data.urls), icon: <MousePointerClick className='w-6 h-6 mr-2' />}
    ]


    return <div>
        <Dashboard cards={cards} heading={"Current overview of smler subscription"}/>
    </div>
}   


export default AdminDashboard